<template>
  <div>
    <b-row class="mb-4 mb-4 justify-content-center aligns-center min-vh-100 align-items-center" style="min-height: 70vh !important;">
      <b-col cols="12">
        <b-card style="width: 80%; margin: 0 auto;" header-tag="header">
            <h2 style="text-align: center;line-height: 50px;font-weight: bold;" class="mb-3">Selamat Datang di Menu</h2>
            <h2 style="text-align: center;line-height: 50px;font-weight: bold;" class="mb-3"><span style="color: #90c!important;">
              {{ whoIs().split(' ').map(dt => dt[0].toUpperCase() + dt.slice(1)).join(' ') }}
            </span></h2>
            <h4 style="text-align: center;">Apa yang ingin anda kerjakan hari ini?</h4>
            <div class="mt-3 text-center" v-if="whoIs('customer service')">
              <b-link to="/order/create" class="mr-3">Input Data Pendaftaran</b-link>
              <b-link to="/order" class="mr-3">Lihat Data Pendaftaran</b-link>
              <b-link to="/report-dashboard">Lihat Laporan</b-link>
            </div>
            <div class="mt-3 text-center" v-if="whoIs('back office')">
              <b-link to="/order" class="mr-3">Lihat Data Jama'ah</b-link>
              <b-link to="/report-dashboard">Lihat Laporan</b-link>
            </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  methods: {
    whoIs: function(role = null) {
      if (role == null) return this.$store.getters['account/accountData'].role
      return this.$store.getters['account/accountData'].role == role
    }
  }
}
</script>